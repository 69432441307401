import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import Form from "@/domains/app/components/Form.vue";
import SheetForm from "@/domains/cruds/sheets/components/Form.vue";
import DogForm from "@/domains/cruds/dogs/components/Form.vue";
import EditUserForm from "@/domains/cruds/users/components/EditUserForm.vue";
import List from "@/domains/search/components/List.vue";
import GicList from "@/domains/cruds/gic/components/List.vue";
import SheetList from "@/domains/cruds/sheets/components/List.vue";
import RequesterList from "@/domains/cruds/requesters/components/List.vue";
import LocationList from "@/domains/cruds/locations/components/List.vue";

import {
    PaginatedResourcesState,
    ResourceBase,
    ResourcesState,
    usePaginatedResourcesState,
    useResourcesState,
} from "@/domains/app/resource";
import { ResourceFormStateFactory } from "@/domains/app/forms";

import { Breed } from "@/domains/cruds/breeds/breeds";
import { Department } from "@/domains/cruds/departments/departments";
import { Dog } from "@/domains/cruds/dogs/dogs";
import {
    ABILITY_READ_GLOBAL_CRUDS,
    ABILITY_WRITE_GLOBAL_CRUDS,
    ABILITY_WRITE_RESTRICTED_CRUDS,
    ROLE_ADMIN,
    ROLE_DELEGATE,
    User,
} from "@/domains/cruds/users/users";
import { Result } from "@/domains/cruds/results/results";
import { Game } from "@/domains/cruds/games/games";
import { WeaponType } from "@/domains/cruds/weaponTypes/weaponTypes";
import { Weapon } from "@/domains/cruds/weapons/weapons";
import { Shot } from "@/domains/cruds/shots/shots";
import { Aim } from "@/domains/cruds/aims/aims";
import { Hunt } from "@/domains/cruds/hunts/hunts";
import { Pursuit } from "@/domains/cruds/pursuits/pursuits";
import { Position } from "@/domains/cruds/positions/positions";
import { HuntType } from "@/domains/cruds/huntTypes/huntTypes";

import { useDepartmentForm } from "@/domains/cruds/departments/forms";
import { useBreedForm } from "@/domains/cruds/breeds/forms";
import { useUserForm } from "@/domains/cruds/users/forms";
import { useResultForm } from "@/domains/cruds/results/forms";
import { useGameForm } from "@/domains/cruds/games/forms";
import { useShotForm } from "@/domains/cruds/shots/forms";
import { useWeaponTypeForm } from "@/domains/cruds/weaponTypes/forms";
import { useWeaponForm } from "@/domains/cruds/weapons/forms";
import { useLocationForm } from "@/domains/cruds/locations/forms";
import { useAimForm } from "@/domains/cruds/aims/forms";
import { useHuntForm } from "@/domains/cruds/hunts/forms";
import { useGicForm } from "@/domains/cruds/gic/forms";
import { usePursuitForm } from "@/domains/cruds/pursuits/forms";
import { usePositionForm } from "@/domains/cruds/positions/forms";
import { useHuntTypeForm } from "@/domains/cruds/huntTypes/forms";
import { useCalibreForm } from "@/domains/cruds/calibres/forms";
import { Calibre } from "@/domains/cruds/calibres/calibres";
import { Pace } from "@/domains/cruds/paces/paces";
import { usePaceForm } from "@/domains/cruds/paces/forms";
import { useAmmunitionForm } from "@/domains/cruds/ammunitions/forms";
import { Ammunition } from "@/domains/cruds/ammunitions/ammunitions";
import { Failure } from "@/domains/cruds/failures/failures";
import { useFailureForm } from "@/domains/cruds/failures/forms";
import { Injury } from "@/domains/cruds/injuries/injuries";
import { useInjuryForm } from "@/domains/cruds/injuries/forms";
import { useRequesterForm } from "@/domains/cruds/requesters/forms";
import { useWeatherForm } from "@/domains/cruds/weather/forms";
import { Weather } from "@/domains/cruds/weather/weather";
import { ListItemConfig, ListItemDriver } from "@/domains/search/list";
import { useUser } from "@/domains/auth/user";
import { computed } from "vue";
import { useProfileForm } from "@/domains/cruds/users/profileForm";
import { useFaqForm } from "@/domains/cruds/faqs/forms";
import { Faq } from "@/domains/cruds/faqs/faqs";
import { request } from "@/domains/api/api";

const { scope: userScope } = useUser();

const routes: Array<RouteRecordRaw> = [
    {
        name: "departments.create",
        path: "/departments/new",
        component: Form,
        props: makeFormProps(useDepartmentForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "departments.edit",
        path: "/departments/:id/edit",
        component: Form,
        props: makeFormProps(useDepartmentForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "departments.index",
        path: "/departments",
        component: List,
        props: makeListProps<Department>(
            "Départements",
            "departments",
            (r) => r.label
        ),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "negative-states.index",
        path: "/negative-states",
        component: List,
        props: (): Record<string, any> => {
            return {
                title: "Etats négatifs",
                getResourcesState: () =>
                    usePaginatedResourcesState(
                        "negative-states",
                        false,
                        computed(() => ({ all: "1", includeSeason: "1" }))
                    ),
                apiResourceBase: "negative-states",
                makeItemDriver: () => ({
                    getLabel: (r: any) =>
                        r.user.first_name +
                        " " +
                        r.user.last_name +
                        " (" +
                        r.user.department.label +
                        ")" +
                        " - " +
                        new Date(r.season.start).getFullYear() +
                        "/" +
                        new Date(r.season.end).getFullYear(),
                    loadingKey: "negative-states.loading.list",
                }),
                itemClicked: (item: ListItemConfig) => {
                    if (
                        !confirm(
                            "Est tu sur de vouloir supprimer " +
                                item.label +
                                " ?"
                        )
                    )
                        return;
                    request("negative-states/" + item.uid, "delete", () => {
                        console.log("Impossible de supprimer");
                    }).then(() => {
                        window.location.reload();
                    });
                },
            };
        },
        meta: {
            abilities: [ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "negative-states.edit",
        path: "/negative-states/:id/edit",
        meta: { abilities: ["impossible"] },
        redirect: { name: "negative-states.index" },
    },
    {
        name: "breeds.create",
        path: "/breeds/new",
        component: Form,
        props: makeFormProps(useBreedForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "breeds.edit",
        path: "/breeds/:id/edit",
        component: Form,
        props: makeFormProps(useBreedForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "breeds.index",
        path: "/breeds",
        component: List,
        props: makeListProps<Breed>("Races", "breeds", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "dogs.create",
        path: "/dogs/new",
        component: DogForm,
    },
    {
        name: "dogs.edit",
        path: "/dogs/:id/edit",
        component: DogForm,
        props: true,
    },
    {
        name: "dogs.index",
        path: "/dogs",
        component: List,
        props: makeListProps<Dog>(
            "Chiens (pas archivés)",
            "dogs",
            (r) => r.name,
            true,
            "Nom"
        ),
    },
    {
        name: "users.create",
        path: "/users/new",
        component: Form,
        props: makeFormProps(useUserForm),
        meta: { abilities: [ABILITY_WRITE_RESTRICTED_CRUDS] },
    },
    {
        name: "profile.edit",
        path: "/users/me",
        component: Form,
        props: makeFormProps(useProfileForm),
    },
    {
        name: "users.edit",
        path: "/users/:id/edit",
        component: EditUserForm,
        meta: {
            canAccessCb: (user: User | null) =>
                user &&
                (user.roles.includes(ROLE_ADMIN) ||
                    user.roles.includes(ROLE_DELEGATE)),
        },
    },
    {
        name: "users.index",
        path: "/users",
        component: List,
        props: makeListProps<User>(
            "Conducteurs",
            "users",
            (r) => r.first_name + " " + r.last_name,
            true,
            "nom ou prénom"
        ),
        meta: {
            canAccessCb: (user: User | null) =>
                user &&
                (user.roles.includes(ROLE_ADMIN) ||
                    user.roles.includes(ROLE_DELEGATE)),
        },
    },
    {
        name: "sheets.create",
        path: "/sheets/new",
        component: SheetForm,
    },
    {
        name: "sheets.edit",
        path: "/sheets/:id/edit",
        component: SheetForm,
        props: true,
    },
    {
        name: "sheets.index",
        path: "/sheets",
        component: SheetList,
    },
    {
        name: "results.create",
        path: "/results/new",
        component: Form,
        props: makeFormProps(useResultForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "results.edit",
        path: "/results/:id/edit",
        component: Form,
        props: makeFormProps(useResultForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "results.index",
        path: "/results",
        component: List,
        props: makeListProps<Result>("Résultats", "results", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "games.create",
        path: "/games/new",
        component: Form,
        props: makeFormProps(useGameForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "games.edit",
        path: "/games/:id/edit",
        component: Form,
        props: makeFormProps(useGameForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "games.index",
        path: "/games",
        component: List,
        props: makeListProps<Game>("Gibiers", "games", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "shots.create",
        path: "/shots/new",
        component: Form,
        props: makeFormProps(useShotForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "shots.edit",
        path: "/shots/:id/edit",
        component: Form,
        props: makeFormProps(useShotForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "shots.index",
        path: "/shots",
        component: List,
        props: makeListProps<Shot>(
            "Tirs",
            "shots",
            (r) => r.label + (r.enabled ? "" : " #désactivé#")
        ),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "requesters.create",
        path: "/requesters/new",
        component: Form,
        props: makeFormProps(useRequesterForm),
    },
    {
        name: "requesters.edit",
        path: "/requesters/:id/edit",
        component: Form,
        props: makeFormProps(useRequesterForm),
    },
    {
        name: "requesters.index",
        path: "/requesters",
        component: RequesterList,
    },
    {
        name: "weapon-types.create",
        path: "/weapon-types/new",
        component: Form,
        props: makeFormProps(useWeaponTypeForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "weapon-types.edit",
        path: "/weapon-types/:id/edit",
        component: Form,
        props: makeFormProps(useWeaponTypeForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "weapon-types.index",
        path: "/weapon-types",
        component: List,
        props: makeListProps<WeaponType>(
            "Types d'armes",
            "weapon-types",
            (r) => r.label
        ),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "weapons.create",
        path: "/weapons/new",
        component: Form,
        props: makeFormProps(useWeaponForm),
    },
    {
        name: "weapons.edit",
        path: "/weapons/:id/edit",
        component: Form,
        props: makeFormProps(useWeaponForm),
    },
    {
        name: "weapons.index",
        path: "/weapons",
        component: List,
        props: makeListProps<Weapon>("Armes", "weapons", (r) => r.label, true),
    },
    {
        name: "locations.create",
        path: "/locations/new",
        component: Form,
        props: makeFormProps(useLocationForm),
    },
    {
        name: "locations.edit",
        path: "/locations/:id/edit",
        component: Form,
        props: makeFormProps(useLocationForm),
    },
    {
        name: "locations.index",
        path: "/locations",
        component: LocationList,
    },
    {
        name: "aims.create",
        path: "/aims/new",
        component: Form,
        props: makeFormProps(useAimForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "aims.edit",
        path: "/aims/:id/edit",
        component: Form,
        props: makeFormProps(useAimForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "aims.index",
        path: "/aims",
        component: List,
        props: makeListProps<Aim>("Visées", "aims", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "hunts.create",
        path: "/hunts/new",
        component: Form,
        props: makeFormProps(useHuntForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "hunts.edit",
        path: "/hunts/:id/edit",
        component: Form,
        props: makeFormProps(useHuntForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "hunts.index",
        path: "/hunts",
        component: List,
        props: makeListProps<Hunt>("Types de chasse", "hunts", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "gics.create",
        path: "/gics/new",
        component: Form,
        props: makeFormProps(useGicForm),
    },
    {
        name: "gics.edit",
        path: "/gics/:id/edit",
        component: Form,
        props: makeFormProps(useGicForm),
    },
    {
        name: "gics.index",
        path: "/gics",
        component: GicList,
    },
    {
        name: "pursuits.create",
        path: "/pursuits/new",
        component: Form,
        props: makeFormProps(usePursuitForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "pursuits.edit",
        path: "/pursuits/:id/edit",
        component: Form,
        props: makeFormProps(usePursuitForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "pursuits.index",
        path: "/pursuits",
        component: List,
        props: makeListProps<Pursuit>("Poursuites", "pursuits", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "positions.create",
        path: "/positions/new",
        component: Form,
        props: makeFormProps(usePositionForm),
    },
    {
        name: "positions.edit",
        path: "/positions/:id/edit",
        component: Form,
        props: makeFormProps(usePositionForm),
    },
    {
        name: "positions.index",
        path: "/positions",
        component: List,
        props: makeListProps<Position>(
            "Postes",
            "positions",
            (r) => r.label,
            true
        ),
    },
    {
        name: "hunt-types.create",
        path: "/hunt-types/new",
        component: Form,
        props: makeFormProps(useHuntTypeForm),
    },
    {
        name: "hunt-types.edit",
        path: "/hunt-types/:id/edit",
        component: Form,
        props: makeFormProps(useHuntTypeForm),
    },
    {
        name: "hunt-types.index",
        path: "/hunt-types",
        component: List,
        props: makeListProps<HuntType>(
            "Traque",
            "hunt-types",
            (r) => r.label,
            true
        ),
    },
    {
        name: "faqs.create",
        path: "/faqs/new",
        component: Form,
        props: makeFormProps(useFaqForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "faqs.edit",
        path: "/faqs/:id/edit",
        component: Form,
        props: makeFormProps(useFaqForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "faqs.index",
        path: "/faqs",
        component: List,
        props: makeListProps<Faq>("FAQ", "faqs", (r) => r.question),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "calibres.create",
        path: "/calibres/new",
        component: Form,
        props: makeFormProps(useCalibreForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "calibres.edit",
        path: "/calibres/:id/edit",
        component: Form,
        props: makeFormProps(useCalibreForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "calibres.index",
        path: "/calibres",
        component: List,
        props: makeListProps<Calibre>("Calibres", "calibres", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "paces.create",
        path: "/paces/new",
        component: Form,
        props: makeFormProps(usePaceForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "paces.edit",
        path: "/paces/:id/edit",
        component: Form,
        props: makeFormProps(usePaceForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "paces.index",
        path: "/paces",
        component: List,
        props: makeListProps<Pace>("Allures", "paces", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "ammunitions.create",
        path: "/ammunitions/new",
        component: Form,
        props: makeFormProps(useAmmunitionForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "ammunitions.edit",
        path: "/ammunitions/:id/edit",
        component: Form,
        props: makeFormProps(useAmmunitionForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "ammunitions.index",
        path: "/ammunitions",
        component: List,
        props: makeListProps<Ammunition>(
            "Munitions",
            "ammunitions",
            (r) => r.label
        ),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "failures.create",
        path: "/failures/new",
        component: Form,
        props: makeFormProps(useFailureForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "failures.edit",
        path: "/failures/:id/edit",
        component: Form,
        props: makeFormProps(useFailureForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "failures.index",
        path: "/failures",
        component: List,
        props: makeListProps<Failure>(
            "Causes d'échec",
            "failures",
            (r) => r.label
        ),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "injuries.create",
        path: "/injuries/new",
        component: Form,
        props: makeFormProps(useInjuryForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "injuries.edit",
        path: "/injuries/:id/edit",
        component: Form,
        props: makeFormProps(useInjuryForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "injuries.index",
        path: "/injuries",
        component: List,
        props: makeListProps<Injury>("Blessures", "injuries", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
    {
        name: "weather.create",
        path: "/weather/new",
        component: Form,
        props: makeFormProps(useWeatherForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "weather.edit",
        path: "/weather/:id/edit",
        component: Form,
        props: makeFormProps(useWeatherForm),
        meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
    },
    {
        name: "weather.index",
        path: "/weather",
        component: List,
        props: makeListProps<Weather>("Météo", "weather", (r) => r.label),
        meta: {
            abilities: [ABILITY_READ_GLOBAL_CRUDS, ABILITY_WRITE_GLOBAL_CRUDS],
        },
    },
];

function makeFormProps<T extends ResourceBase>(
    stateFactory: ResourceFormStateFactory<T>
) {
    return (route: RouteLocationNormalized) => {
        let idStr: string | undefined;
        if (typeof route.params.id === "object") {
            idStr = route.params.id[0];
        } else {
            idStr = route.params.id;
        }
        const { formArgs: args, data } = stateFactory(
            idStr ? parseInt(idStr) : undefined
        );
        args.isResourceForm = true;
        return { ...args, data };
    };
}

type ListProps<T extends ResourceBase> = {
    title: string;
    getResourcesState: () => PaginatedResourcesState<T> | ResourcesState<T>;
    apiResourceBase: string;
    newItemRoute: { name: string };
    makeItemDriver: () => ListItemDriver<T>;
    searchPlaceholder: string | null;
};

function makeListProps<T extends ResourceBase>(
    title: string,
    resourceApiBase: string,
    getLabel: (r: T) => string,
    paginate = false,
    searchable: string | null = null
): ListProps<T> {
    return {
        title,
        getResourcesState: () =>
            paginate
                ? usePaginatedResourcesState<T>(
                      resourceApiBase,
                      searchable !== null,
                      computed(() => ({ scope: userScope.value || "user" }))
                  )
                : useResourcesState<T>(resourceApiBase, searchable !== null),
        apiResourceBase: resourceApiBase,
        newItemRoute: { name: `${resourceApiBase}.create` },
        makeItemDriver: () => ({
            getLabel,
            loadingKey: `${resourceApiBase}.loading.list`,
        }),
        searchPlaceholder: searchable,
    };
}

export default routes;
