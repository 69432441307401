import { computed, ComputedRef, ref, Ref, WritableComputedRef } from "vue";
import { request as makeApiRequest } from "@/domains/api/api";
import { ResourceBase } from "./resource";
import { onMessage } from "./bus";

export const NULL_VALUE_UID =
    "rHBkCvjkm10rkMq11Iq9DinBxxs1giZgdCAzBJ9stv0iR4cccJrEoaxIXwVcBn5OBFAMbj5CwoscSqbvPUtgSBWoTaRsFQhRhjsCJryBoajqCgefheQC1AI3sHENbMFLH9neLON2ic2phQ5Cpi8cQtXXLZ45SELMpOY14nc5tCyjDtKXkL9RB4RunL5BMI4uUCkEEqJuWbfIKVl10ux79MA17zsuqPdFWvwnEifYkVXN4hjRpqotQKm9dCqF0ws";

export const ADD_RELATION_VALUE_UID =
    "abcdefghui11Iq9DinBxxs1giZgdCAzBJ9stv0iR4cccJrEoaxIXwVcBn5OBFAMbj5CwoscSqbvPUtgSBWoTaRsFQhRhjsCJryBoajqCgefheQC1AI3sHENbMFLH9neLON2ic2phQ5Cpi8cQtXXLZ45SELMpOY14nc5tCyjDtKXkL9RB4RunL5BMI4uUCkEEqJuWbfIKVl10ux79MA17zsuqPdFWvwnEifYkVXN4hjRpqotQKm9dCqF0ws";

export type FormFields = Array<
    FieldConfig<any> | FileFieldConfig | StaticFieldConfig | SlotConfig<any>
>;
export type FormDriver = {
    submit?: () => Promise<any>;
    delete?: () => Promise<any>;
};

/**
 * Factory to create resource states
 */
export type ResourceFormStateFactory<T extends ResourceBase> = (
    id: number | undefined
) => FormState<T>;

/**
 * Form State with args and data
 */
export type FormState<T extends ResourceBase> = {
    formArgs: FormParams;
    data: Ref<T | null>;
};

/**
 * Form props
 */
export type FormParams = {
    title: string;
    driver: FormDriver;
    fields: FormFields;
    complementaryFields?: FormFields;
    getErrors: () => Ref<FormErrors | null>;
    isResourceForm?: boolean;
    showSubmitedFlash?: boolean;
    getDisabled?: () => ComputedRef<boolean>;
    deleteButtonLabel?: string;
};

export type FieldChoiceMultiplePriritizedValue = (Record<string, number> & {
    priority: number;
})[];
export type FieldValue = string | number[] | number | null | boolean;

/**
 * Props d'un field
 */
interface BaseFieldConfig {
    label: string;
    name: string;
    getAllowedValues?: () => AllowedValuesType;
    getWarning?: () => ComputedRef<string | null>;
    helpText?: string;
    markAsRequired?: boolean | (() => ComputedRef<boolean>);
}

export type DateFieldFormats = "date" | "time:h" | "time:h:m";

type VisibilityTogglelable<T> =
    | {
          getVisibility?: undefined;
          defaultHidden?: undefined;
          defaultVisible?: undefined;
      }
    | {
          getVisibility: () => ComputedRef<boolean>;
          defaultHidden: T;
          defaultVisible: T | (() => ComputedRef<T>);
      };

type WithRelation<T extends ResourceBase> = {
    formFactory: ResourceFormStateFactory<T>;
};

type WithoutRelation = {
    [key in keyof WithRelation<any>]?: never;
};

export type FieldConfig<T extends FieldValue> =
    | (BaseFieldConfig &
          ({
              getState: () => Ref<FieldValue> | WritableComputedRef<FieldValue>;
          } & (
              | {
                    type:
                        | "text"
                        | "text:hidden"
                        | "boolean"
                        | DateFieldFormats
                        | "number";
                }
              | ({
                    type: "paginated-select";
                    getAllowedValues?: never;
                    getQueryData?: () => ComputedRef<Record<string, string>>;
                } & (
                    | ({ isRelation: true } & WithRelation<any>)
                    | ({ isRelation?: false } & WithoutRelation)
                ))
              | ({
                    type: "choice" | "choice-multiple";
                } & (
                    | ({ isRelation: true } & WithRelation<any>)
                    | ({ isRelation?: false } & WithoutRelation)
                ))
          ) &
              VisibilityTogglelable<T>))
    | ChoiceMultiplePrioritizedConfig;

export type FileFieldConfig = {
    type: "file";
    getState: () => Ref<UploadedFile | null>;
} & BaseFieldConfig &
    VisibilityTogglelable<UploadedFile | null>;

export type StaticFieldConfig = {
    type: "static-img" | "static-text" | "static-link";
    value: ComputedRef<string | null> | Ref<string | null>;
    label?: string;
    getVisibility?: () => ComputedRef<boolean>;
};

export type SlotConfig<T> = {
    type: "slot";
    getVisibility?: () => ComputedRef<boolean>;
    label: string;
    name: string;
    getState: () => Ref<T>;
};

export type PrioritizedValue = (Record<string, number> & {
    priority: number;
})[];
export type ChoiceMultiplePrioritizedConfig = BaseFieldConfig & {
    getState: () =>
        | Ref<PrioritizedValue>
        | WritableComputedRef<PrioritizedValue>;
    type: "choice-multiple-prioritized";
    singularLabel: string;
    column: string;
    allowSame: boolean;
    count: number;
} & VisibilityTogglelable<FieldChoiceMultiplePriritizedValue> &
    (
        | ({ isRelation: true } & WithRelation<any>)
        | ({ isRelation?: false } & WithoutRelation)
    );

// object with key => label (only for 'select' for now)
export type AllowedValuesType = Ref<
    { key: number | string; value: string }[] | null
>;

export type FormErrors = {
    message: string;
    fields: null | FieldsErrors;
};

export type FieldsErrors = {
    [key: string]: string[];
};

export type UploadedFile = {
    link: string;
    filename: string | null;
    filePath: string;
};

/**
 * Create a field state that maps a value in a field to a value in a resource state
 *
 * @param state State to store value
 * @param key key associated to value in state
 * @returns a field state
 */
export function createFieldState<T>(
    state: Ref,
    key: string
): WritableComputedRef<T> {
    return computed({
        get: () => (state.value ? state.value[key] : undefined),
        set: (value: T) => {
            if (state.value) {
                state.value[key] = value;
            }
        },
    });
}

export function createFileState(
    state: Ref,
    key: string
): WritableComputedRef<UploadedFile | null> {
    return computed<UploadedFile | null>({
        get: () => {
            const url = state.value?.links[key] || null;

            return state.value && state.value[key]
                ? {
                      link: url,
                      filename: url ? url.split("/").pop() : null,
                      filePath: state.value[key],
                  }
                : null;
        },
        set: (value: UploadedFile | null) => {
            if (!state.value) return;

            if (value === null) {
                state.value[key] = null;
                state.value.links[key] = null;
            } else {
                state.value[key] = value.filePath;
                state.value.links[key] = value.link;
            }
        },
    });
}

/**
 * Create a field state that maps a value in a field to a value in a resource state
 * Value can be null
 *
 * @param state State to store value
 * @param key key associated to value in state
 * @returns a field state
 */
export function createNullableFieldState<T>(
    state: Ref,
    key: string
): WritableComputedRef<T | null> {
    return computed<T | null>({
        get: () => (state.value ? state.value[key] : NULL_VALUE_UID),
        set: (value: T | null | string) => {
            if (!value || value === NULL_VALUE_UID) {
                value = null;
            }
            if (state.value) {
                state.value[key] = value;
            }
        },
    });
}

/**
 * Get an object
 * @param route what resource to fetch in api
 * @param key what property to use as value
 * @returns allowed values
 */
export function useRelationAsFieldValue(
    route: string,
    key: string,
    allowNull = false,
    queryOptions?: Record<string, string>
): AllowedValuesType {
    const r: AllowedValuesType = ref(null);

    function mapRelationToValue(r: any): { key: string; value: string } {
        return { key: r.id, value: r[key] };
    }

    makeApiRequest<any[]>(route, "get", undefined, queryOptions)
        .then((data) => {
            if (!data) return;

            if (data.length && data[0] && data[0].order_num !== undefined) {
                data = data.sort(
                    (i1, i2) => (i2.order_num || 0) - (i1.order_num || 0)
                );
            }

            if (allowNull) {
                data.unshift({
                    key: NULL_VALUE_UID,
                    value: NULL_VALUE_UID,
                });
            }

            r.value = data.map(mapRelationToValue);
        })
        .catch((e) => {
            r.value = [];
            throw e;
        });

    onMessage<any>("relation." + route + ".created", (value) => {
        r.value?.push(mapRelationToValue(value));
    });

    return r;
}

/**
 * Convert object to form values
 * @param entriesObj Object with a value for each key
 */
export function useObjectEntriesAsFieldValues(
    entriesObj: Record<string | number, string>
): AllowedValuesType {
    return ref(
        Object.entries(entriesObj).map((entry) => ({
            key: entry[0],
            value: entry[1],
        }))
    );
}
