import { computed, ComputedRef, ref } from "vue";
import { Sheet } from "@/domains/cruds/sheets/sheets";
import { Filter, getParsedFilters } from "./filters";
import { request } from "../api/api";
import { useLoading } from "../app/loading";
import { FAILURE_IDS, POSITIVE_FOR_RATE_IDS } from "../cruds/results/results";

export type SheetFilterColumns = {
    [key in keyof Sheet]?: {
        trad: string;
    } & (
        | {
              type: "number" | "date" | "bool" | "season";
              //   allowedFilters: FilterType[];
          }
        | {
              type: "choice";
              choices: Record<string, string>;
          }
        | {
              type: "relation";
              relation: string;
              relationCol: string;
              //   allowedFilters: EqualityFilterType[];
          }
    );
};

export type SheetStatisticsColumns = {
    [key in keyof Sheet]?: { trad: string } & (
        | {
              type: "number";
              showTotal?: true;
              showMean?: never;
              unit?: string | ((isTotal: boolean) => string);
              modifier?: (
                  e: number | null | undefined,
                  isTotal: boolean
              ) => number | null | undefined;
          }
        | {
              type: "number-interval";
              showTotal?: true;
              showMean?: true;
              unit?: string | ((isTotal: boolean) => string);
              modifier?: (
                  e: number | null | undefined,
                  isTotal: boolean
              ) => number | null | undefined;
          }
        | {
              type: "relation";
              relation: string;
              relationCol: string;
              showTotal?: true;
              showMean?: never;
              unit?: never;
              modifier?: never;
          }
    );
};

export type NumberStats = {
    mean: number;
    q1: number;
    q2: number;
    q3: number;
    σ: number;
    total: number;
};

export type IntervalStats = {
    counts: { count: number; interval: [number | null, number | null] }[];
    mean: number;
    total: number;
};

export type RelationStats = {
    counts: Record<
        string,
        {
            value: number;
        }
    >;
    total: number;
};

export type SimpleNumberStats = {
    n: number;
};

export type Statistics = Record<
    keyof SheetStatisticsColumns,
    NumberStats | RelationStats | IntervalStats | SimpleNumberStats | null
>;

type NumberStatsFromApi = { avg: number; sum: number; intervals: never };
type IntervalStatsFromApi = {
    intervals: {
        count: number;
        interval: [number | null, number | null];
    }[];
    sum: number;
    avg: number;
};
type RelationStatsFromApi = { count: number; id: number }[];

type StatisticsFromApi = Record<
    keyof SheetStatisticsColumns,
    null | NumberStatsFromApi | RelationStatsFromApi | IntervalStatsFromApi
>;

const statistics = ref<null | Statistics>(null);
const sheetCountInStatistics = ref(0);

let abortController = new AbortController();

export function useStatistics(immediate = false): {
    data: ComputedRef<null | Statistics>;
    sheetCount: ComputedRef<number>;
    setFilters: (filters: Filter[]) => Promise<void>;
} {
    function isNumberStats(stats: any): stats is NumberStatsFromApi {
        const keys = Object.keys(stats);

        return (
            typeof stats === "object" &&
            keys.length === 2 &&
            keys.includes("avg") &&
            keys.includes("sum")
        );
    }

    function isIntervalStats(stats: any): stats is IntervalStatsFromApi {
        const keys = Object.keys(stats);

        return (
            typeof stats === "object" &&
            keys.length === 3 &&
            keys.includes("avg") &&
            keys.includes("intervals") &&
            keys.includes("sum")
        );
    }

    function getStats(filters?: Filter[]) {
        abortController.abort();
        abortController = new AbortController();

        const { setLoading } = useLoading("statistics.stats");
        setLoading(true);

        return request<{
            total: number;
            data: StatisticsFromApi;
        }>(
            "statistics",
            "get",
            undefined,
            filters ? getParsedFilters(filters) : undefined,
            { signal: abortController.signal }
        )
            .then((rs) => {
                if (!rs?.data) return;
                sheetCountInStatistics.value = rs.total;

                statistics.value = Object.entries(rs.data).reduce<Statistics>(
                    (acc, [key, value]) => {
                        if (value === null) {
                            acc[key] = null;
                        } else if (isNumberStats(value)) {
                            acc[key] = {
                                mean: value.avg,
                                q1: 0,
                                q2: 0,
                                q3: 0,
                                σ: 0,
                                total: value.sum,
                            };
                        } else if (Array.isArray(value)) {
                            if (key === "result_id") {
                                const tmp = value.reduce<{
                                    success: number;
                                    failure: number;
                                }>(
                                    (acc, v) => {
                                        if (
                                            POSITIVE_FOR_RATE_IDS.includes(v.id)
                                        ) {
                                            acc.success += v.count;
                                        } else if (FAILURE_IDS.includes(v.id)) {
                                            acc.failure += v.count;
                                        }
                                        return acc;
                                    },
                                    { success: 0, failure: 0 }
                                );
                                acc["success_rate"] = {
                                    n:
                                        Math.round(
                                            (tmp.success /
                                                (tmp.success + tmp.failure)) *
                                                10000
                                        ) / 100,
                                };
                                if (isNaN(acc["success_rate"].n)) {
                                    acc["success_rate"].n = 0;
                                }
                            }
                            acc[key] = {
                                counts: value.reduce<RelationStats["counts"]>(
                                    (acc, val) => {
                                        acc[val.id + ""] = { value: val.count };
                                        return acc;
                                    },
                                    {}
                                ),
                                total: value.reduce<RelationStats["total"]>(
                                    (acc, val) => acc + val.count,
                                    0
                                ),
                            };
                        } else if (isIntervalStats(value)) {
                            acc[key] = {
                                mean: value.avg,
                                total: value.sum,
                                counts: value.intervals,
                            };
                        } else if (process.env.NODE_ENV === "dev") {
                            console.error("Cannot extract stat from ");
                            console.error(value);
                        }

                        return acc;
                    },
                    {}
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }

    if (immediate) getStats();

    return {
        data: computed(() => statistics.value),
        sheetCount: computed(() => sheetCountInStatistics.value),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setFilters: (filters: Filter[]) => getStats(filters).then(() => {}),
    };
}
