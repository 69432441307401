import { sheetStatisticsColumns } from "./config";
import {
    IntervalStats,
    NumberStats,
    RelationStats,
    SheetStatisticsColumns,
    SimpleNumberStats,
    Statistics,
    useStatistics,
} from "./statistics";
import { getIntervalLabel, getRelationLabel } from "./tools";
import { jsPDF } from "jspdf";
import { read as readXSLX, writeFile as writeXSLX } from "xlsx";
import { chartColors } from "./config";

export function downloadXLSX(heading?: string): string | null {
    const csv = generateCSV(heading);
    if (!csv) return null;

    const tmp = readXSLX(csv, { type: "string" });
    return writeXSLX(tmp, "export.xlsx", { type: "base64" });
}

function generateCSV(heading?: string): string | null {
    const {
        data: { value: allStats },
        sheetCount,
    } = useStatistics();
    if (allStats === null) return null;

    let csv =
        (heading ? heading + "\n" : "") +
        "Taux de réussite," +
        (allStats.success_rate as SimpleNumberStats)?.n +
        ",%" +
        "\nFiches traitées," +
        sheetCount.value +
        "\n\n";

    Object.entries(allStats).forEach(([column, colStats]) => {
        const config = sheetStatisticsColumns[column];
        if (!config || !colStats) return;
        csv += `${config.trad}`;

        if (config.type === "number") {
            /* NUMBER */
            csv +=
                `\nMoyenne,${
                    config.modifier
                        ? config.modifier((colStats as NumberStats).mean, false)
                        : (colStats as NumberStats).mean
                }` +
                (config.unit
                    ? "," +
                      (typeof config.unit === "string"
                          ? config.unit
                          : config.unit(false))
                    : "");
            if (config.showTotal) {
                csv +=
                    `\nTotal,${
                        config.modifier
                            ? config.modifier(
                                  (colStats as NumberStats).total,
                                  true
                              )
                            : (colStats as NumberStats).total
                    }` +
                    (config.unit
                        ? "," +
                          (typeof config.unit === "string"
                              ? config.unit
                              : config.unit(true))
                        : "");
            }
        } else if (config.type === "number-interval") {
            /* NUMBER INTERVAL */
            const stats = colStats as IntervalStats;
            Object.values(stats.counts).forEach((stat) => {
                csv += `\n${getIntervalLabel(column, stat.interval)},${
                    stat.count
                }`;
            });
            if (config.showMean) {
                csv +=
                    `\nMoyenne,${
                        config.modifier
                            ? config.modifier(stats.mean, false)
                            : stats.mean
                    }` +
                    (config.unit
                        ? "," +
                          (typeof config.unit === "string"
                              ? config.unit
                              : config.unit(false))
                        : "");
            }
            if (config.showTotal) {
                csv +=
                    `\nTotal,${
                        config.modifier
                            ? config.modifier(stats.total, true)
                            : stats.total
                    }` +
                    (config.unit
                        ? "," +
                          (typeof config.unit === "string"
                              ? config.unit
                              : config.unit(true))
                        : "");
            }
        } else if (config.type === "relation") {
            /* RELATION */
            const stats = colStats as RelationStats;
            Object.entries(stats.counts).forEach(([key, stat]) => {
                const l = getRelationLabel(column, key)?.replace(",", " ");
                csv += `\n${l || key},${stat.value}`;
            });
            if (config.showTotal) {
                csv += `\nTotal,${stats.total}`;
            }
        }
        csv += "\n\n";
    });

    return csv;
}

type CardConfig = {
    image?: HTMLCanvasElement;
    texts: {
        text: string;
        top: number;
        left: number;
        height: number;
        width: number;
    }[];
    height: number;
    width: number;
};
const PDF_SIZE = [210, 297];
const PDF_CARD_WIDTH = 65;
const PDF_CARD_PADDING = 5;
const PDF_CARD_TEXT_PADDING = 2;

const PDF_CARD_BG = "#eff4f9";
const PDF_TEXT_COLOR = "#13262f";

let PDF_TEXT_LINE_HEIGHT = 1;

export async function generatePDF(
    heading?: string,
    activeFilters?: string
): Promise<jsPDF | null> {
    const doc = new jsPDF("p", "mm", "a4");

    doc.setFontSize(12);
    PDF_TEXT_LINE_HEIGHT = doc.getTextDimensions("|").h;
    const cards_per_row = Math.floor(PDF_SIZE[0] / PDF_CARD_WIDTH);
    const cardXMargin =
        (PDF_SIZE[0] - cards_per_row * PDF_CARD_WIDTH) / (cards_per_row + 1);
    const cardYMaring = 10;

    doc.setFillColor("black");
    doc.text("Statistiques", PDF_SIZE[0] / 2, PDF_TEXT_LINE_HEIGHT * 2, {
        align: "center",
    });

    if (!sheetStatisticsColumns.weight) return null;

    const drawRawAndReset = () => {
        if (!row.length) return;
        let cardX = currX;
        let maxHeight = 0;
        row.forEach((card) => {
            drawCardOnPdf(cardX, currY, doc, card);
            cardX += card.width + cardXMargin;
            if (card.height > maxHeight) {
                maxHeight = card.height;
            }
        });
        row = [];
        currY += cardYMaring + maxHeight;
        cardRowIndex = 0;
    };

    let cardRowIndex = 0;
    const currX = cardXMargin;
    let currY =
        PDF_TEXT_LINE_HEIGHT * 3 +
        cardYMaring +
        (activeFilters ? PDF_TEXT_LINE_HEIGHT * 3 : 0);
    let row: CardConfig[] = [];
    const {
        data: { value: allStats },
        sheetCount,
    } = useStatistics();
    if (allStats === null) return null;

    doc.text(
        sheetCount.value + " fiches traitées",
        PDF_SIZE[0] - cardXMargin,
        PDF_TEXT_LINE_HEIGHT * 2,
        {
            align: "right",
        }
    );

    doc.text(
        "Généré le " + new Date().toLocaleDateString(),
        cardXMargin,
        PDF_TEXT_LINE_HEIGHT * 2,
        {
            align: "left",
        }
    );

    doc.text(
        "Taux de réussite " +
            (allStats.success_rate as SimpleNumberStats)?.n +
            "%",
        PDF_SIZE[0] - cardXMargin,
        PDF_TEXT_LINE_HEIGHT * 2 + cardYMaring,
        {
            align: "right",
        }
    );

    if (heading) {
        doc.text(heading, cardXMargin, PDF_TEXT_LINE_HEIGHT * 2 + cardYMaring, {
            align: "left",
        });
    }

    console.log("filters:", activeFilters);
    if (activeFilters) {
        doc.text(
            "Filtres actifs: " + activeFilters,
            cardXMargin,
            PDF_TEXT_LINE_HEIGHT * 2 + cardYMaring * 2,
            {
                align: "left",
            }
        );
    }

    Object.entries(sheetStatisticsColumns).forEach(([column, config]) => {
        const stats = allStats[column];
        if (!stats || !config) return;
        if (cardRowIndex % cards_per_row !== cardRowIndex) {
            drawRawAndReset();
        }
        const card = getCard(column, stats, config, doc);

        row.push(card);
        if (currY + card.height >= PDF_SIZE[1]) {
            doc.addPage("a4", "p");
            currY = PDF_TEXT_LINE_HEIGHT;
        }
        cardRowIndex++;
    });

    drawRawAndReset();

    return doc;
}

function getCard(
    column: string | number,
    _stats: Statistics[number | string],
    config: NonNullable<SheetStatisticsColumns[string | number]>,
    doc: jsPDF
): CardConfig {
    let height = PDF_CARD_PADDING;

    const texts: CardConfig["texts"] = [];
    let image: CardConfig["image"] = undefined;

    let top = PDF_CARD_TEXT_PADDING;
    const left = 0;

    function appendText(text: string | number, hasPuce = false) {
        text = "" + text;
        const textSize = getTextSize(text, doc, hasPuce);
        textSize.h += texts.length ? PDF_CARD_TEXT_PADDING : 0;

        texts.push({ text, top, left, height: textSize.h, width: textSize.w });
        top += textSize.h;
        height += textSize.h;
    }

    appendText(config.trad);
    height += PDF_CARD_PADDING * 2;
    top += PDF_CARD_PADDING * 2;
    if (config.type === "number") {
        const stats = _stats as NumberStats;
        appendText("Moyenne");
        appendText(
            (config.modifier
                ? config.modifier(stats.mean, false)
                : stats.mean) +
                (config.unit
                    ? typeof config.unit === "string"
                        ? config.unit
                        : config.unit(false)
                    : "")
        );
        if (config.showTotal) {
            appendText("Total");
            appendText(
                (config.modifier
                    ? config.modifier(stats.total, true)
                    : stats.total) +
                    (config.unit
                        ? typeof config.unit === "string"
                            ? config.unit
                            : config.unit(true)
                        : "")
            );
        }
    } else if (config.type === "number-interval") {
        /*  */
        const stats = _stats as IntervalStats;
        Object.values(stats.counts).forEach((stat) => {
            appendText(
                `${getIntervalLabel(column, stat.interval)}: ${stat.count}`,
                true
            );
        });
        if (config.showMean) {
            appendText(
                `Moyenne: ${
                    config.modifier
                        ? config.modifier(stats.mean, false)
                        : stats.mean
                }` +
                    (config.unit
                        ? " " +
                          (typeof config.unit === "string"
                              ? config.unit
                              : config.unit(false))
                        : "")
            );
        }
        if (config.showTotal) {
            appendText(
                `Total: ${
                    config.modifier
                        ? config.modifier(stats.total, true)
                        : stats.total
                }` +
                    (config.unit
                        ? " " +
                          (typeof config.unit === "string"
                              ? config.unit
                              : config.unit(false))
                        : "")
            );
        }
    } else if (config.type === "relation") {
        /* RELATION */
        image =
            (document.getElementById(
                config.relation + "-chart"
            ) as HTMLCanvasElement | null) ?? undefined;
        if (image?.getBoundingClientRect().width === 0) {
            image = undefined;
        }
        height += PDF_CARD_WIDTH;
        const stats = _stats as RelationStats;
        const sortedCounts = Object.entries(stats.counts).sort(
            ([, a], [, b]) => b.value - a.value
        );
        sortedCounts.forEach(([key, stat]) => {
            appendText(
                `${getRelationLabel(column, key) || key}: ${stat.value}`,
                true
            );
        });
        if (config.showTotal) {
            appendText(`Total: ${stats.total}`);
        }
    }

    height += PDF_CARD_PADDING;

    return {
        height,
        width: PDF_CARD_WIDTH,
        texts,
        image,
    };
}

function drawCardOnPdf(
    left: number,
    top: number,
    doc: jsPDF,
    card: CardConfig
) {
    doc.setFillColor(PDF_CARD_BG);
    doc.roundedRect(left, top, PDF_CARD_WIDTH, card.height, 1, 1, "F");

    if (card.image) {
        doc.addImage(
            card.image,
            left + PDF_CARD_PADDING,
            top + PDF_CARD_PADDING,
            PDF_CARD_WIDTH - PDF_CARD_PADDING * 2,
            PDF_CARD_WIDTH - PDF_CARD_PADDING * 2
        );
        top += PDF_CARD_WIDTH;
    }

    card.texts.forEach((textConfig, index) => {
        const offset = index > 0 ? doc.getTextDimensions("#").w * 2 : 0;
        const textLeft =
            left +
            // textConfig.left +
            offset +
            (index == 0
                ? (PDF_CARD_WIDTH - 2 * PDF_CARD_PADDING) / 2 -
                  textConfig.width / 2
                : 0) +
            PDF_CARD_PADDING;
        const textTop = top + textConfig.top + PDF_TEXT_LINE_HEIGHT;
        if (index > 0) {
            // skip title
            doc.setTextColor(chartColors[(index - 1) % chartColors.length]);
            doc.text("#", textLeft - offset / 2, textTop, {});
        }

        doc.setTextColor(PDF_TEXT_COLOR);
        doc.text(textConfig.text, textLeft, textTop, {
            maxWidth: PDF_CARD_WIDTH - PDF_CARD_PADDING * 2 - offset,
        });
    });
}

function getTextSize(text: string, doc: jsPDF, hasPuce = false) {
    return doc.getTextDimensions(text, {
        fontSize: 12,
        maxWidth:
            PDF_CARD_WIDTH -
            PDF_CARD_PADDING * 2 -
            (hasPuce ? doc.getTextDimensions("#").w * 2 : 0),
    });
}
