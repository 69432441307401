import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type Dog = Resource & {
    name: string;
    certified: boolean;
    lof: boolean;
    tatoo: null | string;
    chip: null | string;
    gender: null | "M" | "F";
    breed_id: number;
    master_id: number;
    deleted_at: null | string;
};

export type DogState = ResourceState<Dog>;

/**
 * Create a dog state to get data and its mutators
 * @param id Id of the dog that the state should be related to
 * @returns a dog state with the data and mutators
 */
export function useDog(id?: number): DogState {
    const state: DogState = {
        data: ref(null),
        apiRoute: "dogs",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                name: "",
                certified: false,
                lof: false,
                tatoo: null,
                chip: null,
                gender: null,
                breed_id: -1,
                master_id: useUser().user.value?.id || -1,
                deleted_at: null,
            }),
    };

    return useResourceState(state);
}
