import { SheetFilterColumns, SheetStatisticsColumns } from "./statistics";

export const chartColors = [
    "#36a2eb",
    "#ff6384",
    "#ff9f40",
    "#ffcd56",
    "#4bc0c0",
    "#9966ff",
    "cecbcf",
];

export const sheetColumns: SheetFilterColumns = {
    date: {
        type: "date",
        trad: "Date",
    },
    weight: {
        type: "number",
        trad: "Poids vidé",
    },
    department_id: {
        type: "relation",
        trad: "Département",
        relation: "departments",
        relationCol: "label",
    },
    game_id: {
        type: "relation",
        trad: "Gibier",
        relation: "games",
        relationCol: "label",
    },
    age: {
        type: "number",
        trad: "Age",
    },
    gender: {
        type: "choice",
        trad: "Sexe",
        choices: {
            M: "Male",
            F: "Femelle",
            I: "Indéterminé",
        },
    },
    user_id: {
        type: "relation",
        relation: "users",
        relationCol: "last_name",
        trad: "Conducteur",
    },
    dog_id: {
        type: "relation",
        relation: "dogs",
        relationCol: "name",
        trad: "Chien (pas archivé)",
    },
    requester_id: {
        type: "relation",
        relation: "requesters",
        relationCol: "name",
        trad: "Demandeur",
    },
    location_id: {
        type: "relation",
        relation: "locations",
        relationCol: "label",
        trad: "Lieu",
    },
    weapon_id: {
        type: "relation",
        relation: "weapons",
        relationCol: "label",
        trad: "Arme",
    },
    gic_id: {
        type: "relation",
        relation: "gics",
        relationCol: "label",
        trad: "GIC",
    },
    duration: {
        type: "number",
        trad: "Durée",
    },
    shot_distance: {
        type: "number",
        trad: "Distance de tir",
    },
    disturbed: {
        type: "bool",
        trad: "Dérangé",
    },
    result_id: {
        type: "relation",
        trad: "Résultat",
        relation: "results",
        relationCol: "label",
    },
    hunt_id: {
        trad: "Type de chasse",
        type: "relation",
        relation: "hunts",
        relationCol: "label",
    },
    hunt_type_id: {
        trad: "Traque",
        type: "relation",
        relation: "hunt-types",
        relationCol: "label",
    },
    leash_length: {
        trad: "Longueur au trait",
        type: "number",
    },
    path_age: {
        trad: "Age de la piste",
        type: "number",
    },
    pursuit_id: {
        trad: "Poursuite",
        type: "relation",
        relation: "pursuits",
        relationCol: "label",
    },
    pursuit_length: {
        trad: "Longueur de poursuite",
        type: "number",
    },
    shot_id: {
        type: "relation",
        trad: "Tir",
        relation: "shots",
        relationCol: "label",
    },
    aim_id: {
        type: "relation",
        trad: "Visée",
        relation: "aims",
        relationCol: "label",
    },
    position_id: {
        type: "relation",
        trad: "Poste",
        relation: "positions",
        relationCol: "label",
    },
    pace_id: {
        type: "relation",
        trad: "Allure",
        relation: "paces",
        relationCol: "label",
    },
    calibre_id: {
        type: "relation",
        trad: "Calibre",
        relation: "calibres",
        relationCol: "label",
    },
    ammunition_id: {
        type: "relation",
        trad: "Munition",
        relation: "ammunitions",
        relationCol: "label",
    },
    vehicule_km: {
        trad: "KM véhicule",
        type: "number",
    },
    weather_id: {
        trad: "Météo",
        type: "relation",
        relation: "weather",
        relationCol: "label",
    },
    season_id: {
        type: "season",
        trad: "Saison",
    },
    paid_trip: {
        type: "bool",
        trad: "Déduction kilométrique",
    },
    injuries: {
        type: "relation",
        trad: "Blessure",
        relation: "injuries",
        relationCol: "label",
    },
    failures: {
        type: "relation",
        trad: "Cause d'échec",
        relation: "failures",
        relationCol: "label",
    },
};

export const sheetStatisticsColumns: SheetStatisticsColumns = {
    result_id: {
        trad: "Résultats",
        type: "relation",
        relation: "results",
        relationCol: "label",
    },
    game_id: {
        trad: "Gibiers",
        type: "relation",
        relation: "games",
        relationCol: "label",
        showTotal: true,
    },
    hunt_id: {
        trad: "Types de chasses",
        type: "relation",
        relation: "hunts",
        relationCol: "label",
    },
    leash_length: {
        trad: "Longueur au trait",
        type: "number-interval",
        unit: (isTotal) => (isTotal ? "km" : "m"),
        showTotal: true,
        showMean: true,
        modifier: (e, isTotal) =>
            isTotal && e !== null && e !== undefined ? e / 1000 : e,
    },
    injuries_0: {
        trad: "Blessures principales",
        type: "relation",
        relation: "injuries",
        relationCol: "label",
    },
    injuries_1: {
        trad: "Blessures secondaires",
        type: "relation",
        relation: "injuries",
        relationCol: "label",
    },
    path_age: {
        trad: "Age de la piste",
        type: "number-interval",
        unit: "h",
        showMean: true,
    },
    pursuit_id: {
        trad: "Poursuites",
        type: "relation",
        relation: "pursuits",
        relationCol: "label",
    },
    pursuit_length: {
        trad: "Longueur de poursuite",
        type: "number",
        showTotal: true,
        unit: (isTotal) => (isTotal ? "km" : "m"),
        modifier: (e, isTotal) =>
            isTotal && e !== null && e !== undefined ? e / 1000 : e,
    },
    failures_0: {
        trad: "Cause d'échec principale",
        type: "relation",
        relation: "failures",
        relationCol: "label",
    },
    failures_1: {
        trad: "Cause d'échec secondaire",
        type: "relation",
        relation: "failures",
        relationCol: "label",
    },
    shot_id: {
        type: "relation",
        trad: "Tirs",
        relation: "shots",
        relationCol: "label",
    },
    weight: {
        trad: "Poids vidé",
        type: "number",
        unit: "kg",
        showTotal: true,
    },
    vehicule_km: {
        trad: "KM véhicule",
        type: "number",
        unit: "km",
        showTotal: true,
    },
};
